* {
	margin: 0;
	padding: 0;
}

.App {
	max-width: 1440px;
	align-items: center;
	margin-top: 40px;
	margin-left: auto;
	margin-right: auto;
}

.header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.headerButtons {
	align-self: flex-end;
	align-self: center;
}

.App-logo {
	max-width: 100px;
	/* height: 62px;
	width: 157px; */
	pointer-events: none;
}

.donateButton {
	background: #63edcc;
	border-radius: 8px;
	padding: 16px 48px;
	border: none;
	font-family: "Open Sans";
	font-weight: 700;
	font-size: 16px;
	color: #1b1b1b;
	letter-spacing: 0;
	text-align: center;
	text-transform: uppercase;
	margin-right: 24px;
	transition-duration: 500ms;
	cursor: pointer;
}

.donateButton:hover {
	background: #63edcc;
	box-shadow: 0 15px 60px -15px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
	transition-duration: 500ms;
}
.volunteerButton {
	background: #ffd984;
	border-radius: 8px;
	font-size: 16px;
	color: #1b1b1b;
	letter-spacing: 0;
	text-align: center;
	border-radius: 8px;
	padding: 16px 48px;
	border: none;
	font-family: "Open Sans";
	font-weight: 700;
	font-size: 16px;
	text-transform: uppercase;
	transition-duration: 500ms;
	cursor: pointer;
}

.volunteerButton:hover {
	background: #ffd984;
	box-shadow: 0 15px 60px -15px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
	transition-duration: 500ms;
}

.heroSection {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	background: #fff7ed;
	border-radius: 16px;
	margin-top: 40px;
}

.heroIllustration {
	width: 648px;
	height: 400px;
	align-self: center;
	margin-top: 50px;
	margin-bottom: 50px;
	margin-right: 80px;
}

.heroText {
	align-self: center;
	margin-left: 80px;
}

.heroTitle {
	font-family: "Open Sans";
	font-size: 56px;
	font-weight: 600;
	color: #1b1b1b;
	letter-spacing: 0;
	line-height: 64px;
	text-transform: uppercase;
}

.highlight {
	font-weight: 800;
}

.heroPara {
	font-family: "Open Sans";
	font-size: 24px;
	color: #1b1b1b;
	letter-spacing: 0;
	line-height: 32px;
	margin-top: 24px;
}

.aboutSection {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 756px;
	margin: auto;
	margin-top: 120px;
}

.aboutTitle {
	font-family: "Open Sans";
	font-size: 24px;
	color: #1b1b1b;
	letter-spacing: 0;
	text-align: center;
	text-transform: uppercase;
}

.aboutPara {
	font-family: "Open Sans";
	font-size: 16px;
	color: #1b1b1b;
	letter-spacing: 0;
	text-align: center;
	line-height: 24px;
	margin-top: 40px;
}

.aboutCta {
	align-self: center;
	margin-top: 48px;
}

.ourVolunteersSection {
	position: relative;
	margin-top: 120px;
}

.voluIllustration {
	position: absolute;
	margin-top: 153px;
	margin-left: 40px;
}

.middle-illustration img {
	display: flex;
	justify-content: center;
	margin: auto;
	margin-top: 120px;
	margin-bottom: 120px;
}

.allVolunteers {
	margin-top: 52px;
}

.vhelpIcon {
	margin-bottom: 16px;
}

.vlounteerCard {
	width: 326px;
	height: auto;
	background: #ffffff;
	border: 1px solid #f3f3f3;
	border-radius: 16px;
	margin-right: 40px;
	margin-bottom: 40px;
	transition-duration: 500ms;
}

.vlounteerCard:hover {
	background: #ffffff;
	box-shadow: 0 15px 60px -5px rgba(0, 0, 0, 0.08);
	border-radius: 16px;
	border: 1px solid #ffffff;
	transition-duration: 500ms;
}

.cardContents {
	margin: 24px 32px 24px 32px;
}

.vCardTitle {
	font-family: "Open Sans";
	font-weight: 700;
	font-size: 18px;
	color: #1b1b1b;
	letter-spacing: 0;
	line-height: 24px;
	margin-bottom: 8px;
}

.vCardSkill {
	font-family: "Open Sans";
	font-weight: 600;
	font-size: 16px;
	color: #8d8d8d;
	letter-spacing: 0;
	margin-bottom: 24px;
}

.vCardDescription {
	font-family: "Open Sans";
	font-weight: 400;
	font-size: 16px;
	color: #1b1b1b;
	letter-spacing: 0;
	line-height: 24px;
	margin-bottom: 24px;
	width: 262px;
}

.vCardContact {
	position: flex;
	flex-direction: row;
	justify-content: center;
	cursor: pointer;
}

.vCardEmail {
	font-family: "Open Sans";
	font-weight: 700;
	font-size: 16px;
	color: #1b1b1b;
	letter-spacing: 0;
	border: none;
	background-color: transparent;
	margin-left: 16px;
	margin-right: 16px;
	align-self: center;
	cursor: pointer;
}

.vCardIcon {
	align-self: center;
}

.allVolunteers {
	margin-left: auto;
	margin-right: auto;
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex-wrap: wrap;
}

.donateIllustration {
	position: absolute;
	bottom: -240px;
	right: 30px;
	z-index: -1;
}

.dCardContact {
	position: flex;
	flex-direction: row;
	justify-content: center;
	cursor: pointer;
}

.footer {
	max-width: 1440px;
	justify-content: center;
	margin-top: 120px;
	background: #fff7ed;
	padding-bottom: 2px;
}

.footerIllus {
	position: relative;
}

.footerLogo {
	margin-top: 64px;
	margin-bottom: 20px;
	margin-left: auto;
	margin-right: auto;
	max-width: 100px;
}

.footerSlogan {
	font-family: "Open Sans";
	font-size: 16px;
	color: #1b1b1b;
	letter-spacing: 0;
	text-align: center;
	margin-top: 8px;
}

.footerPara {
	left: 0;
	right: 0;
	margin-left: 453px;
	margin-right: 453px;
	margin-top: 96px;
	max-width: 533px;
	font-family: "Open Sans";
	font-size: 16px;
	color: #1b1b1b;
	letter-spacing: 0;
	text-align: center;
	line-height: 24px;
}

.footerVerse {
	font-family: "Open Sans";
	font-weight: 700;
	font-size: 16px;
	color: #1b1b1b;
	letter-spacing: 0;
	text-align: center;
	margin-top: 24px;
}

.mousepotato {
	font-family: "Open Sans";
	font-size: 12px;
	color: #1b1b1b;
	letter-spacing: 0;
	text-align: center;
	margin-top: 112px;
	margin-bottom: 24px;
}

.footerIllustration {
	position: absolute;
	left: 27px;
	bottom: -440px;
}

.logoSlogan {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: auto;
}

@media screen and (max-width: 375px) {
	.app {
		max-width: 320px;
		align-items: center;
		margin-top: 40px;
		margin-left: auto;
		margin-right: auto;
	}

	.donateButton {
		display: none;
	}

	.volunteerButton {
		display: none;
	}

	.heroSection {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		background: #fff7ed;
		border-radius: 16px;
		margin-top: 40px;
	}

	.heroIllustration {
		width: 15%;
		height: 15%;
		align-self: center;
		margin-top: 50px;
		margin-bottom: 50px;
		/* margin-right: 80px; */
	}

	.heroText {
		align-self: center;
		margin-left: 16px;
	}

	.heroTitle {
		font-family: "Open Sans";
		font-size: 48px;
		font-weight: 600;
		color: #1b1b1b;
		letter-spacing: 0;
		line-height: 56px;
		text-transform: uppercase;
	}

	.highlight {
		font-weight: 800;
	}

	.heroPara {
		font-family: "Open Sans";
		font-size: 24px;
		color: #1b1b1b;
		letter-spacing: 0;
		line-height: 32px;
		margin-top: 24px;
	}
}
